import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import Topic from "./Topic.vue";
export default {
  name: "News",
  components: {
    Topic
  },
  data() {
    const allTopics = [{
      title: "SalesFinder партнерская программа, с которой зарабатывают",
      description: "Реферальная программа сервиса аналитики маркетплейсов. Партнерская программа для заработка простыми словами для начинающих селлеров и менеджеров маркетплейсов",
      photo: require("@/assets/images/dashboard/news/28.10.2024.png"),
      link: "https://www.help.salesfinder.ru/partnership",
      date: "28.10.2024"
    }, {
      title: "Бесплатный Telegram-бот для аналитики карточек и категорий от SalesFinder",
      description: "Новый бесплатный Telegram-бот, который помогает анализировать данные продаж Ozon и Wildberries",
      photo: require("@/assets/images/dashboard/news/22.10.2024.png"),
      link: "https://www.help.salesfinder.ru/salesfinder-free-telegram-bot",
      date: "22.10.2024"
    }, {
      title: "Темная тема SalesFinder",
      description: "Добавили возможность переключать тему и выбирать светлый или темный интерфейс",
      photo: require("@/assets/images/dashboard/news/30.09.2024.png"),
      link: "https://www.help.salesfinder.ru/dark-mode",
      date: "30.09.2024"
    }, {
      title: "Репрайсер: выход из акций на Озон",
      description: 'В инструменте "Репрайсер" добавили возможность выбирать будет ваш товар участвовать в автоматических акциях от Ozon или нет.',
      photo: require("@/assets/images/dashboard/news/16.09.2024.png"),
      link: "https://www.help.salesfinder.ru/out-of-sales-ozon",
      date: "16.09.2024"
    }, {
      title: "Мониторинг цен с учетом Ozon карты и WB кошелька",
      description: "Обновили возможности мониторинга РРЦ, теперь он может учитывать дополнительные скидки по карте Ozon и WB кошельку",
      photo: require("@/assets/images/dashboard/news/25.07.2024.png"),
      link: "https://www.help.salesfinder.ru/monitoring-s-kartoi",
      date: "25.07.2024"
    }, {
      title: "Бесплатный плагин SalesFinder для аналитики товаров и конкурентов на Ozon и Wildberries",
      description: "Теперь наше расширение для Google Chrome и Яндекс Браузер доступно всем пользователям.",
      photo: require("@/assets/images/dashboard/news/20.06.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/eem9ka82m1-besplatnii-plagin-salesfinder-dlya-anali",
      date: "20.06.2024"
    }, {
      title: "Новое в репрайсере: компенсация СПП с учетом скидки кошелька",
      description: "Добавили в сценарий «Компенсация СПП» новую функцию. Теперь вы можете компенсировать цену с учетом Ozon карты или WB кошелька.",
      photo: require("@/assets/images/dashboard/news/13.06.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/jcnbz9icg1-novoe-v-repraisere-kompensatsiya-spp-s-u",
      date: "13.06.2024"
    }, {
      title: "Анализ продаж в поисковой выдачи WB и Ozon",
      description: "Теперь вы можете анализировать продажи с любого поискового запроса на Ozon или Wildberries.",
      photo: require("@/assets/images/dashboard/news/03.06.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/8z2v34auy1-analiz-prodazh-v-poiskovoi-vidachi-wb-i",
      date: "03.06.2024"
    }, {
      title: "Управление минимальным остатком в репрайсере",
      description: "Добавили во все типы сценариев в репрайсере возможность автоматически повышать цены при снижении остатков в карточке товара.",
      photo: require("@/assets/images/dashboard/news/30.05.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/2cu6y2jy91-upravlenie-minimalnim-ostatkom-v-reprais",
      date: "30.05.2024"
    }, {
      title: "Новый сценарий в репрайсере: компенсация СПП",
      description: "Добавили новый сценарий для автоматического изменения цен ваших товаров. Теперь вы можете компенсировать скидку от маркетплейса.",
      photo: require("@/assets/images/dashboard/news/28.05.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/ad4dgs8kp1-novii-stsenarii-v-repraisere-kompensatsi",
      date: "28.05.2024"
    }, {
      title: "Автоматическое управление ценой: репрайсер от SalesFinder",
      description: "Настраивайте автоматические сценарии для управления ценой на свои товары и экономьте свое время с помощью репрайсера от SalesFinder",
      photo: require("@/assets/images/dashboard/news/07.05.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/opxva3buk1-avtomaticheskoe-upravlenie-tsenoi-reprai",
      date: "07.05.2024"
    }, {
      title: "Мониторинг РРЦ в SalesFinder",
      description: "С помощью нового модуля «Мониторинг РРЦ» вы сможете оперативно следить не только за ценами конкурентов, но и отслеживать цены на собственные товары.",
      photo: require("@/assets/images/dashboard/news/12.03.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/jzuvoiv3v1-monitoring-rrts-v-salesfinder",
      date: "12.03.2024"
    }, {
      title: "Новые метрики в отчётах внешней аналитики",
      description: "Вес, цена продавца, % выкупа уже доступны в аналитических отчётах SalesFinder",
      photo: require("@/assets/images/dashboard/news/01.11.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/5ch164h1z1-novie-metriki-v-otchyotah-vneshnei-anali",
      date: "01.11.2023"
    }, {
      title: "Финансовый анализ магазина и товаров на Ozon",
      description: "Поможет оценить структуру расходов, экономическое здоровье магазина по ключевым показателям и финансовое состояние бизнеса",
      photo: require("@/assets/images/dashboard/news/14.09.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/34mybl1x11-finansovii-analiz-magazina-i-tovarov-na",
      date: "14.09.2023"
    }, {
      title: "В отчетах внешней аналитики Wildberries доступен анализ СПП",
      description: "WB больше не отображает СПП, но теперь метрика доступна в наших отчётах. 3 причины отслеживать скидки.",
      photo: require("@/assets/images/dashboard/news/13.09.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/regh543p31-v-otchetah-vneshnei-analitiki-wildberrie",
      date: "13.09.2023"
    }];
    return {
      allTopics,
      openedTopics: 8
    };
  },
  methods: {
    loadMore() {
      this.openedTopics += 8;
    }
  },
  computed: {
    topics() {
      return this.allTopics.filter((item, index) => index < this.openedTopics);
    }
  }
};