var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('list-ms-wrapper', {
    staticClass: "full",
    attrs: {
      "hasItems": _vm.hasItems,
      "hasItemsLoaded": _vm.hasItemsLoaded,
      "small": true,
      "text": "Настройте регулярный мониторинг и уведомления об изменениях товаров, брендов, категорий и продавцов.\n\n    Например: изменение цены на товары, появление нового бренда в категории, новые товары определенного селлера и и другие события.\n    "
    }
  }, [_c('div', {
    staticClass: "full"
  }, [_c('div', {
    staticClass: "full"
  }, [_c('list-ms-table', {
    key: _vm.tableKey,
    staticClass: "table",
    attrs: {
      "hasItems": _vm.hasItems,
      "paginatable": false,
      "configurable": false,
      "small": true
    },
    on: {
      "update:hasItems": function ($event) {
        _vm.hasItems = $event;
      },
      "update:has-items": function ($event) {
        _vm.hasItems = $event;
      }
    }
  }), _c('router-link', {
    staticClass: "btn-link",
    attrs: {
      "to": {
        name: 'ListMs'
      },
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "btn-blue btn"
  }, [_vm._v("Перейти к проектам")])])], 1), _c('modal-add-listM', {
    ref: "modalAddListM",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-listm-import', {
    ref: "modalListmImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };