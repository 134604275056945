var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "video"
  }, [_c('iframe', {
    staticClass: "video__item",
    attrs: {
      "width": "355",
      "height": "200",
      "src": _vm.data.link,
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  }), _c('span', {
    staticClass: "video__title"
  }, [_vm._v(" " + _vm._s(_vm.data.title))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };