import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import MainTitle from "@/components/MainTitle.vue";
import UniversalCard from "./UniversalCard.vue";
import ExternalAnalytics from "@/components/Dashboard/ExternalAnalytics.vue";
import QueriesSelection from "@/components/Dashboard/QueriesSelection.vue";
import MyShops from "@/components/Dashboard/MyShops.vue";
import OrdersComponent from "@/components/Dashboard/Orders.vue";
import PositionMonitoring from "@/components/Dashboard/PositionMonitoring.vue";
import Lists from "@/components/Dashboard/Lists.vue";
import ListMs from "@/components/Dashboard/ListMs.vue";
import TableSettingsColumns from "@/components/DataTable/TableSettingsColumns.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getMarketplacesListArray } from "@/utils/enums";
export default {
  meta: {
    title: "Главная"
  },
  components: {
    MainTitle,
    UniversalCard,
    ExternalAnalytics,
    QueriesSelection,
    TableSettingsColumns,
    MyShops,
    OrdersComponent,
    PositionMonitoring,
    Lists,
    ListMs,
    AppSelect
  },
  data() {
    const queriesSelectionMarketplaces = [...getMarketplacesListArray()];
    return {
      show_table_settings_columns: false,
      columnSettings: null,
      queriesSelectionMarketplaces,
      queriesSelectionSelectedMarketplace: queriesSelectionMarketplaces[0],
      qSettingsLoaded: false
    };
  },
  //&:
  props: {
    /*
    // Send API requests to load/save setttings (columns order and visiblity)
    // persistSettings: {
    //     type: Boolean,
    //     default: true,
    // },
    */
    // Table identifier - used for saving settings and filters
    tableId: {
      type: String,
      ////required: true,
      default: "dashboard"
    }

    /*
    qSettingsLoaded: {
        type: Boolean,
        default: false,
    },
    */
  },
  //&:
  mounted() {
    ////alert("LS-111");
    this.loadSettings();
  },
  methods: {
    //&:
    async loadSettings() {
      if (!this.$store.state.user.user) {
        ////alert("L444");
        this.qSettingsLoaded = true;
        return;
      }

      /*
      // if (!this.persistSettings) {
      //     ////alert("L444");
      //     return;
      // }
      */

      ////alert("L555");
      const remoteSettings = await this.$store.dispatch("table/getSettings", {
        table_id: this.tableId
      });

      ////alert("L555: remoteSettings="+JSON.stringify(remoteSettings))

      if (remoteSettings && Array.isArray(remoteSettings)) {
        ////alert("L666");
        const settings = remoteSettings.map(remoteColumn => {
          if (!(remoteColumn !== null && remoteColumn !== void 0 && remoteColumn.name)) {
            return undefined;
          }
          const column = {
            name: remoteColumn.name
          };
          if (typeof remoteColumn.show === "boolean") {
            column.show = remoteColumn.show;
          }
          if (typeof remoteColumn.position === "number") {
            column.position = remoteColumn.position;
          }
          return column;
        }).filter(item => !!item);

        ////alert("L777");
        this.$set(this, "columnSettings", settings);
      }
      this.qSettingsLoaded = true;
    },
    onSettingsChange(v) {
      this.columnSettings = v;
      ////alert("S222");

      if (!this.$store.state.user.user) {
        ////alert("S333");
        return;
      }

      /*
      // if (!this.persistSettings) {
      //     ////alert("S444");
      //     return;
      // }
      */

      ////alert("S555");
      this.$store.dispatch("table/putSettings", {
        table_id: this.tableId,
        data: this.columnSettings
      });
    }
  },
  computed: {
    columns() {
      ////alert("col-111");
      let aC = [{
        title: "Wildberries: анализ ниш и продаж конкурентов",
        show: true,
        position: 1,
        name: "wildberries"
      }, {
        title: "Ozon: анализ ниш и продаж конкурентов",
        show: true,
        position: 2,
        name: "ozon"
      }, {
        title: "Мои магазины: обзор показателей",
        show: true,
        position: 3,
        name: "highlights"
      }, {
        title: "Мои магазины: лента заказов",
        show: true,
        position: 4,
        name: "orders"
      }, {
        title: "Подбор поисковых запросов",
        show: true,
        position: 5,
        name: "queriesSelection"
      }, {
        title: "Мониторинг позиций товаров",
        show: true,
        position: 6,
        name: "positionMonitoring"
      }, {
        title: "Мои списки",
        show: true,
        position: 7,
        name: "lists"
      }, {
        title: "Контроль изменений",
        show: true,
        position: 8,
        name: "changeControl"
      }, {
        title: "Материалы для начала работы",
        show: true,
        position: 9,
        name: "materials"
      }, {
        title: "Поддержка клиентов",
        show: true,
        position: 10,
        name: "support"
      }];
      return aC;
    },
    settings() {
      const columns = JSON.parse(JSON.stringify(this.columns));
      return columns.map(column => {
        var _this$columnSettings;
        const item = (_this$columnSettings = this.columnSettings) === null || _this$columnSettings === void 0 ? void 0 : _this$columnSettings.find(setting => setting["name"] === column.name);
        if (item) {
          column.position = item.position;
          column.show = item.show;
        }
        return column;
      });
    },
    settingsChanged() {
      return this.columnSettings && this.columnSettings.length;
    }
  }
};