var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('main-title', [_vm._v(" Материалы по работе с сервисом ")]), _c('div', {
    staticClass: "link-blocks"
  }, _vm._l(_vm.linkBlocks, function (item, index) {
    return _c('LinkBlock', {
      key: index,
      attrs: {
        "data": item
      }
    });
  }), 1), _c('main-title', [_vm._v(" Полезные видео инструкции и уроки ")]), _c('div', {
    staticClass: "videos"
  }, _vm._l(_vm.videos, function (item, index) {
    return _c('Video', {
      key: index,
      attrs: {
        "data": item
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };