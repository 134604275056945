var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('main-title', [_vm._v(" Панель инструментов SalesFinder ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto view-btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.show_table_settings_columns = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/settings.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Настроить вид "), _vm.settingsChanged ? _c('div', {
    staticClass: "indicator"
  }) : _vm._e()])], 1), _c('div', {
    staticClass: "grid-wrap"
  }, [_vm.qSettingsLoaded && _vm.settings[0].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[0].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[0].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" Модуль внешней аналитики позволяет проанализировать показатели категорий маркетплейса, найти самые продаваемые товары, провести конкурентный анализ по бренду или поставщику. "), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-category-analytics",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('external-analytics', {
          attrs: {
            "mp": "wb"
          }
        })];
      },
      proxy: true
    }], null, false, 3565116300)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[1].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[1].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[1].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" Модуль внешней аналитики позволяет проанализировать показатели категорий маркетплейса, найти самые продаваемые товары, провести конкурентный анализ по бренду или поставщику."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-category-analytics",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('external-analytics', {
          attrs: {
            "mp": "ozon"
          }
        })];
      },
      proxy: true
    }], null, false, 1110271148)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[2].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[2].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[2].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" Модуль “Мои магазины” открывает доступ к расширенной аналитике продаж, товаров и поставок вашего магазина на OZON или Wildberries. Для подключения вам понадобиться API ключ."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-seller-analytics",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('my-shops')];
      },
      proxy: true
    }], null, false, 2948237860)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[3].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[3].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[3].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" После подключения магазина к модулю “Мои магазины” вам станет доступна живая лента заказов и продаж, а также дополнительные инструменты для анализа товаров и оптимизации поставок."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-seller-analytics",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('orders-component')];
      },
      proxy: true
    }], null, false, 1909957629)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[4].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[4].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('span', {
          staticStyle: {
            "margin-right": "10px"
          }
        }, [_vm._v(" " + _vm._s(_vm.settings[4].title) + " ")]), _c('app-select', {
          staticStyle: {
            "width": "140px"
          },
          attrs: {
            "items": _vm.queriesSelectionMarketplaces,
            "fullwidth": ""
          },
          model: {
            value: _vm.queriesSelectionSelectedMarketplace,
            callback: function ($$v) {
              _vm.queriesSelectionSelectedMarketplace = $$v;
            },
            expression: "queriesSelectionSelectedMarketplace"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" В инструменте подбора запросов вы можете найти наиболее эффективные ключевые слова для контента карточек своих товаров на Wildberries и OZON, что позволит улучшить их видимость среди покупателей маркетплейсов и увеличить продажи."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-keyword-tool",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('queries-selection', {
          attrs: {
            "selected-marketplace": _vm.queriesSelectionSelectedMarketplace
          }
        })];
      },
      proxy: true
    }], null, false, 3932717092)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[5].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[5].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[5].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" Модуль мониторинга позиций позволяет настроить ежедневное отслеживание позиций ваших товаров по целевым поисковым запросам на Wildberries и OZON."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-position-monitoring",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('position-monitoring')];
      },
      proxy: true
    }], null, false, 2858032888)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[6].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[6].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[6].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" Данный инструмент позволяет создавать ваши собственные наборы (списки) из любых доступных для анализа в сервисе сущностей. Например, мы можете создать список потенциально интересных товаров для запуска продаж или список брендов конкурентов."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-my-lists",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('lists')];
      },
      proxy: true
    }], null, false, 476799291)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[7].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[7].position
    }
  }, [_c('universal-card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[7].title))];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        return [_vm._v(" Модуль “Контроль изменений” позволяет настроить автоматическое ежедневное отслеживание важных изменений по указанному вами списку товаров, категорий, брендов или продавцов (изменение цен, новые товары у бренда и др)."), _c('br'), _c('br'), _c('a', {
          staticClass: "link",
          attrs: {
            "href": "https://help.salesfinder.ru/salesfinder-events-monitoring",
            "target": "_blank"
          }
        }, [_vm._v(" Подробнее ")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('list-ms')];
      },
      proxy: true
    }], null, false, 3209467568)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[8].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[8].position
    }
  }, [_c('universal-card', {
    attrs: {
      "btn-route": 'https://help.salesfinder.ru/'
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[8].title))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('p', {
          staticStyle: {
            "margin-right": "30px"
          }
        }, [_vm._v(" Мы подготовили для вас набор обучающих материалов для эффективной работы с инструментами сервиса SalesFinder ")]), _c('img', {
          attrs: {
            "src": require(`@/assets/images/dashboard/book.svg`),
            "width": "150"
          }
        })])];
      },
      proxy: true
    }, {
      key: "btn",
      fn: function () {
        return [_vm._v("Перейти в обучающий центр")];
      },
      proxy: true
    }], null, false, 3740208359)
  })], 1) : _vm._e(), _vm.qSettingsLoaded && _vm.settings[9].show ? _c('div', {
    staticClass: "grid single",
    style: {
      order: _vm.settings[9].position
    }
  }, [_c('universal-card', {
    attrs: {
      "btn-route": {
        name: 'Contact'
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.settings[9].title))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('p', {
          staticStyle: {
            "margin-right": "30px"
          }
        }, [_vm._v(" Мы всегда рады помочь! Свяжитесь с нами, если у вас возникли какие-либо вопросы или пожелания по работе сервиса ")]), _c('img', {
          attrs: {
            "src": require(`@/assets/images/dashboard/question.svg`),
            "width": "150"
          }
        })])];
      },
      proxy: true
    }, {
      key: "btn",
      fn: function () {
        return [_vm._v("Связаться с поддержкой")];
      },
      proxy: true
    }], null, false, 2537865545)
  })], 1) : _vm._e()]), _c('table-settings-columns', {
    attrs: {
      "show": _vm.show_table_settings_columns,
      "columns": _vm.columns,
      "model-value": _vm.columnSettings,
      "title": "Настройки вида",
      "description": "Настройки отображения карточек и их порядок"
    },
    on: {
      "close": function ($event) {
        _vm.show_table_settings_columns = false;
      },
      "change": function ($event) {
        return _vm.onSettingsChange($event);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };