var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('has-tokens', {
    staticClass: "full",
    attrs: {
      "small": true,
      "text": "Подключите свой магазин на OZON или Wildberries для доступа к\n        расширенной аналитике ваших товаров, продаж, поставок и финансовых\n        показателей",
      "img": require('@/assets/images/wireframes/myShops.png')
    }
  }, [_c('div', {
    staticClass: "full"
  }, [_c('div', {
    staticClass: "highlights__filters"
  }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
    staticClass: "highlights__select",
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.marketplace,
      callback: function ($$v) {
        _vm.marketplace = $$v;
      },
      expression: "marketplace"
    }
  }) : _vm._e(), _c('app-select-multi', {
    staticClass: "highlights__select",
    attrs: {
      "items": _vm.select_tokens_items
    },
    model: {
      value: _vm.selectedTokens,
      callback: function ($$v) {
        _vm.selectedTokens = $$v;
      },
      expression: "selectedTokens"
    }
  }), _c('div', {
    staticClass: "report-settings"
  }, [_vm.calendar ? _c('range-calendar', {
    attrs: {
      "boundaries": _vm.$store.state.connections.calendarBoundaries
    },
    model: {
      value: _vm.calendar,
      callback: function ($$v) {
        _vm.calendar = $$v;
      },
      expression: "calendar"
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "chart-wrap"
  }, [_vm.reportSettings ? _c('entity-charts', {
    staticClass: "chart",
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.$store.state.connections.calendar,
      "no-dynamic": true
    }
  }) : _vm._e()], 1), _c('router-link', {
    staticClass: "btn-link",
    attrs: {
      "to": {
        name: 'Highlights',
        params: {
          mp: _vm.marketplace.id
        }
      },
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "btn-blue btn"
  }, [_vm._v("Перейти к анализу")])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };