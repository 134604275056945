var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', {
    staticClass: "link",
    attrs: {
      "href": _vm.data.link,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.data.title))]), _c('svg', {
    attrs: {
      "data-name": "Layer 1",
      "viewBox": "0 0 200 200",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    staticClass: "fill-000000 fill-ff6600",
    attrs: {
      "d": "M100 15a85 85 0 1 0 85 85 84.93 84.93 0 0 0-85-85Zm0 150a65 65 0 1 1 65-65 64.87 64.87 0 0 1-65 65ZM97.5 57.5a9.9 9.9 0 0 0-14 14L112 100l-28.5 28.5a9.9 9.9 0 0 0 14 14L126 114a19.92 19.92 0 0 0 0-28.5Z",
      "fill": "#000000"
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };