import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import DataTable from "@/components/DataTable/DataTable";
import { getEntityTableColumns } from "@/utils/enums";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      if (this.small) {
        query.page_size = 5;
      }
      return this.$store.dispatch(this.action, {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      let preferred = this.reportSettings.metric;
      if (preferred === "revenue") {
        preferred = ["revenue", "sold"];
      }
      let columns = [{
        title: "№",
        position: 1,
        show: true,
        name: "index",
        width: 50,
        type: "number"
      }, {
        title: "Бренд",
        position: 2,
        show: true,
        name: "brand",
        width: 240,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                ...this.routeParams,
                b_id: item.b_id
              },
              query: {
                fbs: this.reportSettings.fbs,
                date: this.reportSettings.date,
                date2: this.reportSettings.date2
              }
            },
            text: item.brand,
            target: this.$route.name === "Rating" || this.$route.name === "Dashboard" ? "_blank" : null
          };
        }
      }, ...getEntityTableColumns("brand", 3, preferred)];
      if (this.small) {
        columns = columns.filter(item => item.name === "brand" || item.name === "revenue");
      }
      return columns;
    }
  }
};