var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.columns ? _c('div', [_c('data-table', _vm._b({
    attrs: {
      "table-id": "mpTable",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "selectable": false,
      "paginatable": false,
      "item-id": "id"
    }
  }, 'data-table', _vm.$attrs, false))], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };