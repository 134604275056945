var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card"
  }, [_vm.$slots.title ? _c('div', {
    staticClass: "card__title"
  }, [_vm._t("title"), _vm.$slots.tooltip ? _c('Tooltip', {
    attrs: {
      "position": "top left"
    }
  }, [_vm._t("tooltip")], 2) : _vm._e()], 2) : _vm._e(), _vm.$slots.description ? _c('div', {
    staticClass: "card__description"
  }, [_vm._t("description")], 2) : _vm._e(), _vm.$slots.content ? _c('div', {
    staticClass: "card__content"
  }, [_vm._t("content")], 2) : _vm._e(), _vm.$slots.btn ? _c('div', {
    staticClass: "card__btn"
  }, [_c('button', {
    staticClass: "btn-blue btn-link",
    on: {
      "click": _vm.navigate
    }
  }, [_vm._t("btn")], 2)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };