import Tooltip from "@/components/Tooltip";
export default {
  name: "UniversalCard",
  components: {
    Tooltip
  },
  props: ["btnRoute"],
  methods: {
    navigate() {
      if (typeof this.btnRoute === "object") {
        const routeData = this.$router.resolve(this.btnRoute);
        window.open(routeData.href, "_blank");
      } else {
        window.open(this.btnRoute, "_blank");
      }
    }
  }
};