var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('SeoWrapper', {
    staticClass: "full",
    attrs: {
      "small": true,
      "text": "Настройте ежедневный мониторинг позиций ваших товаров по целевым поисковым запросам"
    }
  }, [_c('div', {
    ref: "block",
    staticClass: "full"
  }, [_c('monitoring-table', {
    staticClass: "table",
    attrs: {
      "report-settings": _vm.reportSettings,
      "paginatable": false,
      "configurable": false,
      "small": true
    }
  }), _c('router-link', {
    staticClass: "btn-link",
    attrs: {
      "to": {
        name: 'PositionMonitoring'
      },
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "btn-blue btn"
  }, [_vm._v("Перейти к проектам")])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };