import ModalAddList from "@/components/modals/ModalAddList.vue";
import ModalListImport from "@/components/modals/ModalListImport.vue";
import ListsTable from "@/components/List/ListsTable.vue";
import ListsWrapper from "@/components/List/Wrapper.vue";
export default {
  name: "PositionMonitoring",
  components: {
    ListsTable,
    ModalAddList,
    ModalListImport,
    ListsWrapper
  },
  data() {
    return {
      refreshKey: 0,
      hasItems: this.$store.state.user.user ? null : false,
      hasItemsLoaded: false
    };
  },
  async created() {
    const result = await this.$store.dispatch("list/getLists", {
      page: 1
    });
    //console.log(result);
    this.hasItems = !!result.items.length;
    this.hasItemsLoaded = true;
  },
  methods: {
    onAdded(item) {
      this.refreshKey++;
      this.hasItems = true;
      setTimeout(() => {
        this.$refs.modalListImport.open({
          item,
          freshList: true
        });
      }, 100);
    },
    onCreateList() {
      this.$access.hasOrThrow("lists");
      this.$refs.modalAddList.open();
    }
  },
  computed: {
    reportSettings() {
      return {};
    },
    tableKey() {
      return this.refreshKey;
    }
  }
};