import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async tableLoader({
      query
    }) {
      return await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbOrders", "connections/getOzSales", {}, {
        types: ["awaiting_deliver", "awaiting_packaging", "cancelled", "delivering"]
      });
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      let columns = this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nmid",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "Номер заказа",
        show: true,
        name: "gNumber",
        type: " number",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Артикул",
        show: true,
        name: "supplierArticle",
        type: "text",
        width: 130,
        filter: "text",
        noOverflow: true
      }, {
        title: "Бренд",
        show: true,
        name: "brand",
        type: "text",
        filter: "text",
        width: 160
      }, {
        title: "Наименование",
        show: true,
        name: "subject",
        type: "text",
        filter: "text",
        width: 220,
        getImage: item => item.image
      }, {
        title: `Размер`,
        show: true,
        name: "techSize",
        type: "text",
        filter: "text",
        width: 100
      }, {
        title: `Категория`,
        show: true,
        name: "category",
        type: "text",
        filter: "text",
        width: 120
      }, {
        title: `Кол-во`,
        show: true,
        name: "quantity",
        type: "number",
        width: 90,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Цена`,
        show: true,
        name: "totalPrice",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `Дисконт`,
        show: true,
        name: "discountPercent",
        type: "number",
        width: 100,
        filter: "numberRange"
      },
      // {
      //     title: `Номер WB`,
      //     show: true,
      //     name: "docNumber",
      //     type: "text",
      //     width: 100,
      //     filter: "text",
      // },
      {
        title: `Дата заказа`,
        show: true,
        name: "docDate",
        type: "date",
        filter: "dateRange",
        width: 120
      }, {
        title: `Дата обновления`,
        show: true,
        name: "lastChangeDate",
        type: "text",
        filter: "dateRange",
        width: 160
      }, {
        title: `Штрих-код`,
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `Склад отгрузки`,
        show: true,
        name: "warehouseName",
        type: "text",
        width: 150,
        filter: "text"
      }, {
        title: `Область`,
        show: true,
        name: "oblast",
        type: "text",
        width: 250,
        filter: "text"
      }, {
        title: `Номер поставки`,
        show: true,
        name: "incomeID",
        type: "number",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `ID Позиции`,
        show: true,
        name: "odid",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Отменен`,
        show: true,
        name: "is_cancel",
        type: "text",
        width: 100
      }, {
        title: `Дата отмены`,
        show: true,
        name: "cancel_dt",
        type: "text",
        filter: "dateRange",
        width: 100
      },
      // {
      //     title: `Стикер`,
      //     show: true,
      //     name: "sticker",
      //     type: "text",
      //     width: 120,
      //     filter: "text",
      // },
      {
        title: `Дата добавления`,
        show: true,
        name: "date_add",
        type: "date",
        filter: "dateRange",
        width: 160
      }] : [{
        title: "SKU",
        show: true,
        name: "sku",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "Артикул",
        show: true,
        name: "offer_id",
        type: "text",
        width: 140,
        noOverflow: true,
        filter: "text"
      }, {
        title: "Номер заказа",
        show: true,
        name: "order_id",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Номер отправления",
        show: true,
        name: "posting_number",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Наименование",
        show: true,
        name: "name",
        type: "text",
        width: 400,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "Кол-во",
        show: true,
        name: "quantity",
        type: "number",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Цена",
        show: true,
        name: "price",
        type: "number",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Старая цена",
        show: true,
        name: "old_price",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Комиссия",
        show: true,
        name: "commission_amount",
        type: "money",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Комиссия %",
        show: true,
        name: "commission_percent",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Выплата",
        show: true,
        name: "payout",
        type: "money",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Дата заказа",
        show: true,
        name: "created_at",
        type: "text",
        width: 200,
        filter: "dateRange",
        noOverflow: true
      }, {
        title: "Склад",
        show: true,
        name: "warehouse_name",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Тип доставки",
        show: true,
        name: "delivery_type",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "Регион",
        show: true,
        name: "region",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Город",
        show: true,
        name: "city",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Способ оплаты",
        show: true,
        name: "payment_type_group_name",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Статус",
        show: true,
        name: "status",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Дата добавления",
        show: true,
        name: "date_add",
        type: "text",
        width: 200,
        filter: "dateRange",
        noOverflow: true
      }];
      if (this.small) {
        columns = columns.filter(column => {
          return column.title === "Наименование" || column.title === "Цена" || column.title === "Дисконт" || column.title === "Дата заказа";
        });
      }
      return columns;
    }
  }
};