var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "full"
  }, [_c('entity-search', {
    staticClass: "search",
    attrs: {
      "placeholder": "Поиск по товарам: SKU, название или ссылка на товар",
      "no-data-text": "Товары не найдены",
      "text-prop": "title",
      "loader": _vm.searchLoader,
      "get-route": _vm.getRoute
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function ({
        item,
        text
      }) {
        return [_c('div', {
          staticClass: "img"
        }, [item.image ? _c('img', {
          attrs: {
            "src": item.image
          },
          on: {
            "error": function ($event) {
              $event.target.src = require('@/assets/images/img.svg');
            }
          }
        }) : _vm._e()]), _c('span', {
          attrs: {
            "title": item.title
          },
          domProps: {
            "innerHTML": _vm._s(text)
          }
        })];
      }
    }])
  }), _c('span', {
    staticClass: "title"
  }, [_vm._v("Топ 5 товаров по выручке")]), _vm.ratingBindings ? _c('products-table', _vm._b({
    staticClass: "table",
    attrs: {
      "small": true
    }
  }, 'products-table', _vm.ratingBindings, false)) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };