var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page"
  }, [_c('div', {
    staticClass: "lists-page__content"
  }, [_c('app-tabs', {
    staticClass: "tabs",
    attrs: {
      "tabs": _vm.tabs
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }), _c(_vm.getTabComponent(_vm.activeTab.id), {
    tag: "component"
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };