var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', {
    staticClass: "topic",
    attrs: {
      "href": _vm.topic.link,
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "topic__content"
  }, [_c('span', {
    staticClass: "topic__date"
  }, [_vm._v(_vm._s(_vm.topic.date))]), _c('h3', {
    staticClass: "topic__title"
  }, [_vm._v(_vm._s(_vm.topic.title))]), _c('p', {
    staticClass: "topic__description"
  }, [_vm._v(" " + _vm._s(_vm.topic.description) + " ")]), _c('button', {
    staticClass: "btn-blue btn-link"
  }, [_vm._v("Подробнее")])]), _c('img', {
    staticClass: "topic__photo",
    attrs: {
      "src": _vm.topic.photo,
      "alt": "Photo"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };