var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "full"
  }, [_c('app-tabs', {
    staticClass: "tabs",
    attrs: {
      "tabs": _vm.tabs,
      "route-param": "type"
    },
    model: {
      value: _vm.tabs_active,
      callback: function ($$v) {
        _vm.tabs_active = $$v;
      },
      expression: "tabs_active"
    }
  }), _vm.tabs_active ? _c(_vm.getTableComponent(_vm.tabs_active.id), {
    key: _vm.tableKey,
    tag: "component",
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "mp": _vm.mp
    }
  }) : _vm._e(), _c('router-link', {
    staticClass: "btn-link",
    attrs: {
      "to": {
        name: _vm.tabs_active.route,
        params: {
          mp: _vm.mp
        }
      },
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "btn-blue btn"
  }, [_vm._v("Перейти к анализу")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };