var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('lists-wrapper', {
    staticClass: "full",
    attrs: {
      "hasItems": _vm.hasItems,
      "hasItemsLoaded": _vm.hasItemsLoaded,
      "small": true,
      "text": "Создайте собственные подборки товаров, категорий, брендов и продавцов для оперативного доступа к их показателям"
    }
  }, [_c('div', {
    staticClass: "full"
  }, [_c('div', {
    staticClass: "full"
  }, [_c('lists-table', {
    key: _vm.tableKey,
    staticClass: "table",
    attrs: {
      "hasItems": _vm.hasItems,
      "paginatable": false,
      "configurable": false,
      "small": true
    },
    on: {
      "update:hasItems": function ($event) {
        _vm.hasItems = $event;
      },
      "update:has-items": function ($event) {
        _vm.hasItems = $event;
      }
    }
  }), _c('router-link', {
    staticClass: "btn-link",
    attrs: {
      "to": {
        name: 'Lists'
      },
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "btn-blue btn"
  }, [_vm._v("Перейти к спискам")])])], 1), _c('modal-add-list', {
    ref: "modalAddList",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-list-import', {
    ref: "modalListImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };