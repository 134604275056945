var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "full"
  }, [_c('entity-search', {
    staticClass: "search",
    attrs: {
      "placeholder": "Поиск по брендам",
      "no-data-text": "Бренды не найдены",
      "text-prop": "brand",
      "loader": _vm.searchLoader,
      "get-route": _vm.getRoute
    }
  }), _c('span', {
    staticClass: "title"
  }, [_vm._v("Топ 5 брендов по выручке")]), _vm.ratingBindings ? _c('brands-table', _vm._b({
    staticClass: "table",
    attrs: {
      "small": true
    }
  }, 'brands-table', _vm.ratingBindings, false)) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };