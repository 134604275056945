var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "news"
  }, [_vm._l(_vm.topics, function (topic, index) {
    return _c('Topic', {
      key: index,
      attrs: {
        "topic": topic
      }
    });
  }), _vm.openedTopics < _vm.allTopics.length ? _c('button', {
    staticClass: "btn-outline btn-link",
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v(" Показать ещё ")]) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };